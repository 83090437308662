import { FacebookRounded } from "@mui/icons-material";
import { FormEvent, useState } from "react";
import { isLogged, signIn } from "../../@core/domain/users";
import { HttpResponse } from "../../@core/gateway/http";
import Button from "../../Components/Button";
import Input from "../../Components/Form/Input";
import InputMagicEye from "../../Components/Form/Input/InputMagicEye";
import { Form } from "../../Components/Form/styles";
import { Agreement, LoginContainer, LoginPage, Logo } from "./styles";
import BasicModal from "../../Components/Modal";

export default function Login() {
    const redirectOnLogin = (): void => {
        window.location.href = '/products'
    }

    if (isLogged()) redirectOnLogin()

    const [sendingForm, setSendingForm] = useState<boolean>(false)

    const attemptLogin = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        try {
            setSendingForm(true)
            event.preventDefault()
            const response: HttpResponse<null> = await signIn(
                event.currentTarget.email.value,
                event.currentTarget.password.value
            )

            if (!response.isSuccess) {
                alert(response.message)
                return
            }

            redirectOnLogin()
        } catch (error) {
            console.error(error)
            alert(`Ocorreu um erro inesperado. Contate o suporte.\nDetalhes:${JSON.stringify(error)}`)
        } finally {
            setSendingForm(false)
        }
    }

    return (
        <LoginPage>
            <LoginContainer>
                <Logo src="/logo-super-barn.png" />
                <Form onSubmit={attemptLogin}>
                    <Input label="E-mail" type="email" name="email" placeholder="Digite seu e-mail" required={true} disabled={sendingForm} />
                    <InputMagicEye label="Senha" name="password" placeholder="Digite sua senha" required={true} disabled={sendingForm} />

                    <Button type="submit" color="primary" style="filled" disabled={sendingForm}>
                        {!sendingForm ? 'Entrar' : 'Aguarde...'}
                    </Button>
                    <Button color="primary" style="text" disabled={sendingForm}>Esqueceu a senha?</Button>
                    
                    <BasicModal buttonText="Criar Nova Conta" modalType="signup"></BasicModal>
                    {/* <Button color="facebook" style="outlined" icon={FacebookRounded} disabled={sendingForm}>Entrar com Facebook</Button> */}

                    <Agreement>
                        <p>
                            Ao criar conta ou fazer login pelo Facebook você concorda com os nossos
                        </p>
                        <p>
                            <a href="#">Termos e Condições</a>
                        </p>
                    </Agreement>
                </Form>
            </LoginContainer>
        </LoginPage>
    )
}