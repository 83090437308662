import {
    BrowserRouter, //replaces "Switch" used till v5
    Route, Routes
} from 'react-router-dom';
import './App.css';
import { initFirebase } from "./helpers/firebase";

import Login from './pages/Login';
import Products from './pages/Products';
import Orders from './pages/Orders';
import Account from './pages/Account';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from './styles';
import { NavProvider } from './@core/context/NavContext';
import { UserProvider } from './@core/context/UserContext';

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: "#ffffff",
        },
    },
});

function App() {
    initFirebase()

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <UserProvider>
                    <NavProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Login />} />
                                <Route path="/products" element={<Products />} />
                                <Route path="/orders" element={<Orders />} />
                                <Route path="/account" element={<Account />} />
                                {/* <Route path="/" element={<Products />} /> */}
                            </Routes>
                        </BrowserRouter>

                    </NavProvider>
                </UserProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
