import { SvgIconComponent } from "@mui/icons-material";
import React, { RefObject } from "react";
import { FilledButton, OutlinedButton, TextButton } from "./styles";

interface Props {
    color: "primary" | "facebook"
    style: "filled" | "text" | "outlined"
    type?: "submit"
    icon?: SvgIconComponent
    disabled?: boolean
    children?: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    innerRef?: RefObject<HTMLButtonElement>
}

export default function Button(props: Props) {
    let ButtonComponent: typeof FilledButton | typeof TextButton | typeof OutlinedButton
    switch (props.style) {
        case "filled":
            ButtonComponent = FilledButton
            break

        case "text":
            ButtonComponent = TextButton
            break

        case "outlined":
            ButtonComponent = OutlinedButton
            break

        default:
            throw new Error(`${props.style} not implemented`)
    }

    return (
        <ButtonComponent type={props.type ?? "button"} color={props.color} disabled={props.disabled ?? false} ref={props.innerRef} onClick={props.onClick}>
            <>
                {props.icon && React.createElement(props.icon)}
                {props.children}
            </>
        </ButtonComponent>
    )
}