import { AxiosError } from 'axios';
import { CartType2, UserType } from '../../@types';
import constants from '../../helpers/constants';
import { http, HttpResponse } from '../gateway/http';

export const getCurrentUserId = (): string => {
    if (!isLogged()) return '';
    const userId = localStorage.getItem('userId');

    return userId ? userId : '';
};

export const getToken = (): string => {
    if (!isLogged()) return '';
    const token = localStorage.getItem('idToken');

    return token ? token : '';
};

export const getRefreshToken = (): string => {
    if (!isLogged()) return '';
    const refreshToken = localStorage.getItem('refreshToken');

    return refreshToken ? refreshToken : '';
};

export const getCurrentUserIdToken = (): string => {
    if (!isLogged()) return '';
    const idToken = localStorage.getItem('uidToken');
    console.log('user idToken: ' + idToken);

    return idToken ? idToken : '';
};

export const isLogged = (): boolean => {
    const idToken: string | null = localStorage.getItem('idToken');
    if (!idToken?.length) return false;

    const refreshToken: string | null = localStorage.getItem('refreshToken');
    if (!refreshToken?.length) return false;

    const userId: string | null = localStorage.getItem('userId');
    if (!userId?.length) return false;

    return true;
};

export const signIn = async (
    email: string,
    password: string
): Promise<HttpResponse<null>> => {
    return new Promise((resolve, reject) => {
        const SIGN_IN_URL = 'userFunctions/user-api/sign-in';

        http.post(SIGN_IN_URL, { email, password })
            .then((response) => {
                const responseData: {
                    idToken: string;
                    refreshToken: string;
                    userId: string;
                } = response.data;

                localStorage.setItem('idToken', responseData.idToken);
                localStorage.setItem('refreshToken', responseData.refreshToken);
                localStorage.setItem('userId', responseData.userId);

                resolve({
                    isSuccess: true,
                    message: 'Login bem sucedido',
                });
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof AxiosError) {
                    resolve({
                        isSuccess: false,
                        message:
                            error.response?.data?.message ??
                            'Ocorreu um erro inesperado',
                    });
                }

                reject(error);
            });
    });
};

export const signUp = async (
    nome: string,
    lastname: string,
    email: string,
    celular: string,
    cpf: string,
    password: string
): Promise<HttpResponse<null>> => {
    return new Promise((resolve, reject) => {
        const SIGN_UP_URL = 'userFunctions/user-api/create-user';

        http.post(SIGN_UP_URL, {
            user: {
                information: {
                    name: `${nome} ${lastname}`,
                    email: email,
                    cpf: cpf,
                    phone: celular,
                }
            }, password: password
        })
            .then((response) => {
                const responseData: {
                    idToken: string;
                    refreshToken: string;
                    userId: string;
                } = response.data;

                localStorage.setItem('idToken', responseData.idToken);
                localStorage.setItem('refreshToken', responseData.refreshToken);
                localStorage.setItem('userId', responseData.userId);

                resolve({
                    isSuccess: true,
                    message: 'Login bem sucedido',
                });
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof AxiosError) {
                    resolve({
                        isSuccess: false,
                        message:
                            error.response?.data?.message ??
                            'Ocorreu um erro inesperado',
                    });
                }

                reject(error);
            });
    });
};

export const getUserAllCarts = async (): Promise<CartType2[]> => {
    const userId = getCurrentUserId();
    const token = getToken();

    const SIGN_UP_URL = `userFunctions/user-api/get-all-user-carts/${userId}`;
    const GET_CARTS_URL = `userFunctions/user-api/get-user-carts-by-store/${userId}/${constants.STORE_ID}`;

    console.log(GET_CARTS_URL);

    const response = await http.get(GET_CARTS_URL, {
        headers: { Authorization: `Bearer ${token}` },
    });

    const allCarts = response.data;

    console.log('response:', response.data);

    return allCarts;
}

export const getUserInformation = async (): Promise<UserType> => {
    const userId = getCurrentUserId();

    const GET_USER_URL = `userFunctions/user-api/get-user/${userId}`;

    const response = await http.get(GET_USER_URL);

    return response.data;
}

export const updateUserInDB = async (userToUpdate: UserType): Promise<UserType> => {
    const userId = getCurrentUserId();
    const UPDATE_USER_URL = `userFunctions/user-api/update-user/${userId}`;

    const response = await http.put(UPDATE_USER_URL, { user: userToUpdate });
    return response.data;

}