import styled from "styled-components"

const padding = 16

export const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const Header = styled.header`
    justify-content: space-between;
    padding: ${padding}px;
    background: linear-gradient(0deg, rgba(85, 101, 0, 0.08), rgba(85, 101, 0, 0.08)), #FEFFFB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding-bottom: 0px;
    position: fixed;
    width:-webkit-fill-available !important;
    z-index: 999;
`

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
`


export const CartContainer = styled.div`
    border-radius: 100px;
    padding: 8px;
    background-color: #E3E3D3;
    height: 24px;
`

export const CartContainerOpened = styled.div`
    border-radius: 100px;
    padding: 8px;
    background-color: #556500;
    height: 24px;
`
export const NavRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: -20px;
    width: 20%;
`

export const NavLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: -20px;
`