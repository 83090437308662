import React, { useEffect } from "react";
import { ProductType } from "../../@types";
import { AddressInformation } from "../../@types/address";
import { Cart } from "../../@types/cart";
import { Coupom } from "../../@types/coupom";
import { Item } from "../../@types/item";
import { Product } from "../../@types/product";
import constants from '../../helpers/constants';
import { getCurrentUserId } from '../domain/users';

export type CartContextType = {
    cart: Cart
    couponApplied?: Coupom
    isOpen: boolean
    itemsCounter: number
    value: number
    refreshTotals: (cart?: Cart) => void
    toggleCart?: () => void
    incrementItem: (product: ProductType) => void
    decrementItem: (product: ProductType) => void
    updateAddress: (address: any) => void
    setPaymentMethod: (paymentMethod: string) => void
    setIsDeliveryMethod: (isDelivery: boolean) => void
    applyCoupom: (coupom: Coupom) => void
    removeCoupon: () => void
}

const CartContext = React.createContext<CartContextType>({
    cart: {
        storeId: constants.STORE_ID,
        items: [],
        isBadFormatted: false,
        information: {
        }
    },
    isOpen: false,
    itemsCounter: 0,
    value: 0.0,
    refreshTotals: () => { throw new Error("Método não implementado") },
    incrementItem: () => { return (null) },
    decrementItem: () => { return (null) },
    updateAddress: () => { return null },
    setPaymentMethod: () => { return null },
    setIsDeliveryMethod: () => { return null },
    applyCoupom: () => { return null },
    removeCoupon: () => { return null }
})

export type CartProviderProps = {
    children: React.ReactNode
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
    const [cart, setCart] = React.useState<Cart>({
        storeId: constants.STORE_ID,
        clientId: getCurrentUserId(),
        items: [],
        isBadFormatted: false
    });
    const [coupomApplied, setCoupomApplied] = React.useState<Coupom | null>(null);
    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    const [itemsCounter, setItemsCounter] = React.useState<number>(0)
    const [value, setValue] = React.useState<number>(0)
    const [products, setProducts] = React.useState<ProductType[]>([])

    useEffect(() => {
        let totalQuantity = 0;
        let totalValue = 0;
        for (const item of cart.items) {
            totalQuantity += item.qty;
        }
        setItemsCounter(totalQuantity);
        totalValue = cart.information?.estimatedValue ? cart.information.estimatedValue : 0;
        setValue(totalValue);
    }, [cart])

    useEffect(() => {
        if (coupomApplied !== null) {
            const cartToUpdate = updateCartWithDicount(cart);
            setCart(cartToUpdate);
        }

    }, [coupomApplied])

    const refreshTotals = (cart?: Cart) => {
        const totalItems = cart?.items?.length ?? 0
        setItemsCounter(totalItems)

        const totalValue = cart?.items?.reduce((partialSum, item) => partialSum + item.value, 0) ?? 0
        setValue(totalValue)
    }

    const toggleCart = () => setIsOpen(!isOpen);

    /**
     * função para incrementar um produto no carrinho
     * @param {ProductType} product 
     */
    function incrementItem(product: ProductType) {
        const cartToUpdate = { ...cart };
        let itemIncremented = false;
        // verifica se o produto ja existe e incrementa
        for (const item of cartToUpdate.items) {
            if (item.product.id === product.id) {
                item.qty += 1;
                item.value = Number((item.qty * (product.value ? product.value : 1)).toFixed(2));
                item.approximateUnity = item.qty * product.unityClick;
                item.approximateWeight = Number((item.qty * (product.approximateWeight ? product.approximateWeight : 1)).toFixed(2));
                itemIncremented = true;
            }
        }

        // se o for acima nao encontrou produto, addiciona um novo produto
        if (!itemIncremented) {
            const item: Item = {
                qty: 1,
                value: Number(((product.value ? product.value : 1)).toFixed(2)),
                approximateUnity: product.unityClick,
                approximateWeight: Number(((product.approximateWeight ? product.approximateWeight : 1)).toFixed(2)),
                product
            };
            cartToUpdate.items.push(item);
        }

        const estimatedValue = calculateEstimatedValue(cartToUpdate);
        cartToUpdate.information = cartToUpdate.information ?
            { ...cartToUpdate.information, estimatedValue: estimatedValue } :
            { estimatedValue: estimatedValue };
        if (coupomApplied) {
            const cartWithDiscount = updateCartWithDicount(cartToUpdate);
            setCart(cartWithDiscount);
        } else {
            setCart(cartToUpdate);
        }
    }

    /**
     * função para decrementar um produto no carrinho
     * @param {ProductType} product 
     */
    function decrementItem(product: ProductType) {
        const cartToUpdate = { ...cart };

        for (const item of cartToUpdate.items) {
            if (item.product.id === product.id) {
                if (item.qty > 1) {
                    item.qty -= 1;
                    item.value = Number((item.qty * (product.value ? product.value : 1)).toFixed(2));
                    item.approximateUnity = item.qty * product.unityClick;
                    item.approximateWeight = Number((item.qty * (product.approximateWeight ? product.approximateWeight : 1)).toFixed(2));
                } else {
                    const index = cartToUpdate.items.indexOf(item);
                    cartToUpdate.items.splice(index, 1); // Remove the item from the array
                }
            }
        }

        const estimatedValue = calculateEstimatedValue(cartToUpdate);
        cartToUpdate.information = cartToUpdate.information ?
            { ...cartToUpdate.information, estimatedValue: estimatedValue } :
            { estimatedValue: estimatedValue };
        // if (cartToUpdate.information.coupons && cartToUpdate.information.coupons[0] != '') {
        if (coupomApplied) {
            if(estimatedValue < coupomApplied.rulerValueMin){
                removeCoupon(estimatedValue);
                return;
            }
            const cartWithDiscount = updateCartWithDicount(cartToUpdate);
            setCart(cartWithDiscount);
        } else {
            setCart(cartToUpdate);
        }
    }

    // any pois ha divergencia entre os tipos AddressType do user e AddressInformation do cart
    /**
     * Função para atualizar o endereço no carrinho, caso um dos parametros não é passado, então nao irá ser atualizado
     * mantendo o valor anterior.
     * @param {Object} addressData - The address data to be updated.
     * @param {string} addressData.cep - The postal code.
     * @param {string} addressData.street - The street name.
     * @param {string} addressData.number - The street number.
     * @param {string} [addressData.complement] - The address complement (optional).
     * @param {string} [addressData.reference] - The reference point (optional).
     * @param {string} addressData.borough - The borough or district.
     * @param {string} addressData.city - The city.
     * @param {string} addressData.state - The state.
     * @returns {void}
     */
    function updateAddress({ cep, street, number, complement, reference, borough, city, state }: any): void {
        const updateCart = { ...cart };

        if (!updateCart.information) {
            updateCart.information = { address: { cep, street, number, complement, reference, borough, city, state } };
        } else if (!updateCart.information.address) {
            updateCart.information.address = { cep, street, number, complement, reference, borough, city, state };
        } else {
            if (cep) updateCart.information.address.cep = cep;
            if (street) updateCart.information.address.street = street;
            if (number) updateCart.information.address.number = number;
            if (complement) updateCart.information.address.complement = complement;
            if (reference) updateCart.information.address.reference = reference;
            if (borough) updateCart.information.address.borough = borough;
            if (city) updateCart.information.address.city = city;
            if (state) updateCart.information.address.state = state;
        }
        setCart(updateCart);
    }

    /**
     * função atualizar o modo de pagamento no carrinho
     * @param {string} paymentMethod 
     */
    function setPaymentMethod(paymentMethod: string) {
        const updateCart = { ...cart };

        updateCart.information = updateCart.information ?
            { ...updateCart.information, payment: { channel: paymentMethod } } :
            { payment: { channel: paymentMethod } }

        setCart(updateCart);
    }

    /**
     * função atualizar o parametro delivery no carrinho
     * @param {string} isDelivery 
     */
    function setIsDeliveryMethod(isDelivery: boolean) {
        const updateCart = { ...cart };

        updateCart.information = updateCart.information ?
            { ...updateCart.information, delivery: isDelivery } :
            { delivery: isDelivery };

        console.log('updateCart', updateCart);
        setCart(updateCart);
    }

    /**
     * função calcular o valor estimado do carrinho
     * @param {Cart} cart 
     */
    function calculateEstimatedValue(cart: Cart) {
        let totalValue = 0;
        for (const item of cart.items) {
            totalValue += item.value;
        }
        //adicionar logica para verificar cupom aqui
        return Number(totalValue.toFixed(2));
    }

    function applyCoupom(coupom: Coupom) {
        setCoupomApplied(coupom);
    }

    function calculateDiscount(estimatedValue: number): number {
        if (!coupomApplied) return 0;

        if (coupomApplied.rulerKind === 'carrinho') {
            if (coupomApplied.rulerValueType === 'numerico') {
                return coupomApplied.rulerValue;
            } else {
                const discount = estimatedValue * (coupomApplied.rulerValue / 100);
                return Number(discount.toFixed(2));
            }
        }
        return 0;
    }

    function updateCartWithDicount(cart: Cart): Cart {
        const estimatedValue = cart.information?.estimatedValue ? cart.information.estimatedValue : 0;
        const discountToApply = calculateDiscount(estimatedValue);
        const estimatedValueWithDiscount = estimatedValue - discountToApply < 0 ?
            0 : estimatedValue - discountToApply;

        const couponCode = coupomApplied?.couponCode ? coupomApplied.couponCode : '';
        const cartToUpdate = {
            ...cart,
            information: {
                ...cart.information,
                estimatedValue: estimatedValueWithDiscount,
                discount: discountToApply * -1,
                estimatedOriginalValue: estimatedValue,
                coupons: [couponCode],
            },
        };
        return cartToUpdate;
    }

    function removeCoupon(newEstimatedValue?: number):void {
        setCoupomApplied(null);

        const cartToupdate = {...cart};
        if(cartToupdate.information){
            if(newEstimatedValue) {
                cartToupdate.information.estimatedValue = newEstimatedValue
            } else {
                cartToupdate.information.estimatedValue = cartToupdate.information.estimatedOriginalValue;
            }
            delete cartToupdate.information.discount;
            delete cartToupdate.information.estimatedOriginalValue;
            delete cartToupdate.information.coupons;
        }
        setCart(cartToupdate);
    }


    return (
        <CartContext.Provider value={{
            cart,
            isOpen,
            itemsCounter,
            value,
            refreshTotals,
            toggleCart,
            incrementItem,
            decrementItem,
            updateAddress,
            setPaymentMethod,
            setIsDeliveryMethod,
            applyCoupom,
            removeCoupon,
        }}>
            {children}
        </CartContext.Provider>
    );
};


export default CartContext;