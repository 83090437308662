import styled from "styled-components";

export const CartContentImpl = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 24px 16px;
`

export const CartContentCategory = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: 100%
`

export const CartContentCategoryName = styled.h4`
    margin: 0;
    line-height: 1;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
`

export const Label = styled.span`
    text-align: left;
`

export const Number = styled.span`
    text-align: right;
`

export const Product = styled.li`
    display: inline-flex;
    justify-content: space-between;
    font-size: .8rem;
    column-gap: 12px;

    ${Label}:nth-child(1) {
        flex: 1 1 0;
    }
`

export const CartContentCategoryProductList = styled.ul`
    display: inline-flex;
    flex-direction: column;
    row-gap: 8px;
    list-style: none;
    padding: 0;
`