import { CircularProgress } from "@mui/material";
import { LoadingContainer } from "./styles";


export function Loading() {
    return (
        <LoadingContainer>
            <CircularProgress />
        </LoadingContainer>
    )
}