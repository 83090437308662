
import { Add, Remove } from "@mui/icons-material";
import React from "react";
import { RefObject, useRef, useState } from "react";
import CartContext from "../../@core/context/CartContext";
import { ProductType } from "../../@types";
import Button from "../Button";
import Input from "../Form/Input";
import { ActionButtonsWrapper, Container, Description, Headline, Image, InfoContainer, Title } from "./styles";

type ProductCardProps = {
    product: ProductType
}

export default function ProductCard({ product }: ProductCardProps) {

    const quantityRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)
    const decreaseButtonRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null)
    const increaseButtonRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null)
    const [quantity, setQuantity] = useState<number | null>(0)
    //adicionado o contexto do carrinho, para utilizar a função de incrementar item
    const useCart = React.useContext(CartContext);

    const handleQuantityChange = (value: string) => setQuantity(value.length ? parseInt(value) : null)

    // função ao clicar para incrementar item
    const increaseQuantity = () => {
        //usa a função do contexto, la na função é adicionado o item nos estados do context
        // console.log(product)
        useCart.incrementItem(product);
        setQuantity(quantity ? quantity + 1 : 1)
    }

    // função ao clicar para remover item
    const decreaseQuantity = () => {
        //usa a função do contexto, la na função é adicionado o item nos estados do context
        useCart.decrementItem(product);
        setQuantity(quantity ? Math.max(quantity - 1, 0) : 0)
    }

    return <Container>
        <Image src={product.durl} alt={product.name} />
        <InfoContainer>
            <Title>{product.name}</Title>
            <Headline>
                <div>R$ {product.value?.toLocaleString("pt-br")}</div>
            </Headline>
            <Description>
                Burger vegetal a base de soja, grão de bico e ervilha. Surpreenda-se com o sabor e textura. 43g de proteína por hambúrguer.
            </Description>
            <ActionButtonsWrapper>
                <Button color="primary" style="outlined" icon={Remove} innerRef={decreaseButtonRef} disabled={!quantity} onClick={decreaseQuantity} />
                <Input type="number" name="quantity" innerRef={quantityRef} min={0} value={quantity ?? ""} onChange={(event) => handleQuantityChange(event.target.value)} />
                <Button color="primary" style="outlined" icon={Add} innerRef={increaseButtonRef} onClick={increaseQuantity} />
            </ActionButtonsWrapper>
        </InfoContainer>
    </Container>;
}
