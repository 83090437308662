import styled from "styled-components";
import { colors } from "../../../styles";

export const InputWrapper = styled.div`
    position: relative;
`

export const Label = styled.label`
    position: absolute;
    top: -.75rem;
    left: 16px;
    font-size: .75rem;
    padding: 4px;
    background: #FEFFFB;
    color: ${colors.primary};
`

export const Input_ = styled.input`
    padding: 16px;
    width: 100%;
    border-radius: 4px;
    border: 2px solid ${colors.primary};
    font-size: 1rem;
    box-sizing: border-box;
`