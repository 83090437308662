import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '../Button';
import Modal from '@mui/material/Modal';
import { style } from './styles.js';
import HorizontalLinearStepper from '../Stepper';
import SignupForm from '../Signup';


type Props = {
    buttonText: string,
    modalType: string,

}



export default function BasicModal(props: Props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    if (props.modalType=='checkout') {

        return (
            <div>
                <Button onClick={handleOpen} color='primary' style='filled'>{props.buttonText}</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
    
                >
                    <Box sx={style}>
                        <HorizontalLinearStepper></HorizontalLinearStepper>
                    </Box>
                </Modal>
            </div>
        );
    }

    if (props.modalType=='signup') {

        return (
            <div>
                <Button onClick={handleOpen} color='primary' style='text'>{props.buttonText}</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
    
                >
                    <Box sx={style}>
                        <SignupForm buttonText='Criar Conta'></SignupForm>
                    </Box>
                </Modal>
            </div>
        );
    }

    if (props.modalType=='cupom') {

        return (
            <div>
                <Button onClick={handleOpen} color='primary' style='outlined'>{props.buttonText}</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
    
                >
                    <Box sx={style}>
                        Ops! Desculpe, no momento não há cupons cadastrados.
                    </Box>
                </Modal>
            </div>
        );
    }

    else {
        return (
            <div>
                <Button onClick={handleOpen} color='primary' style='filled'>{props.buttonText}</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
    
                >
                    <Box sx={style}>
                        <HorizontalLinearStepper></HorizontalLinearStepper>
                    </Box>
                </Modal>
            </div>
        );
    }

}

