import { FacebookRounded } from "@mui/icons-material";
import { FormEvent, useState } from "react";
import { isLogged, signUp } from "../../@core/domain/users";
import { HttpResponse } from "../../@core/gateway/http";
import Button from "../Button";
import Input from "../Form/Input";
import InputMagicEye from "../Form/Input/InputMagicEye";
import { Form } from "../Form/styles";
import { Agreement, SignupContainer, SignupPage, Logo } from "./styles";

export default function CardForm() {
    

    const [sendingForm, setSendingForm] = useState<boolean>(false)

    const attemptSaveCard = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        try {
            setSendingForm(true)
            event.preventDefault()
            // const response: HttpResponse<null> = await signUp(
            //     event.currentTarget.email.value,
            //     event.currentTarget.password.value
            // )

            // if (!response.isSuccess) {
            //     alert(response.message)
            //     return
            // }

        } catch (error) {
            console.error(error)
            alert(`Ocorreu um erro inesperado. Contate o suporte.\nDetalhes:${JSON.stringify(error)}`)
        } finally {
            setSendingForm(false)
        }
    }

    return (
        <SignupPage>
            <SignupContainer>
                <Form onSubmit={attemptSaveCard}>
                    <Input label="Nome" type="nome" name="nome" placeholder="Nome como escrito no cartão" required={true} disabled={sendingForm} />                    
                    <Input label="Número do Cartão" type="numero" name="numero" placeholder="xxxx xxxx xxxx xxxx" required={true} disabled={sendingForm} />
                    <Input label="Vencimento" type="vencimento" name="vencimento" placeholder="MM/AA" required={true} disabled={sendingForm} />
                    <Input label="CVV" type="codigo" name="codigo" placeholder="código verificador" required={true} disabled={sendingForm} />
                    
                    <Button type="submit" color="primary" style="filled" disabled={sendingForm}>
                        {!sendingForm ? 'Salvar' : 'Aguarde...'}
                    </Button>
                </Form>
            </SignupContainer>
        </SignupPage>
    )
}