import React, { useEffect } from 'react';
import { CartProvider } from '../../@core/context/CartContext';
import { getUserAllCarts } from '../../@core/domain/users';
import { CartType2 } from '../../@types';
import { CartWrapper } from '../../Components/Cart';
import Header from '../../Components/Header';
import { StoreContainer } from './styles';
import { OrdersContainer } from './styles';
import OrderCard from '../../Components/OrderCard';
import CartContent from '../../Components/Cart/CartContent';
import CartContext from '../../@core/context/CartContext';
import NavContext from '../../@core/context/NavContext';
import { HttpResponse } from '../../@core/gateway/http';
import { Cart } from '../../@types/cart';



// WIP >> Usar a mesma estrura para puxar e exibir os carrinhos do usuário 

export default function Orders() {
    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [orders, setOrders] = React.useState<CartType2[]>([]);
    const [isPending, setIsPending] = React.useState<boolean>(true);
    const cartContext = React.useContext(CartContext);


    useEffect(() => {
        setIsPending(true);
        getUserAllCarts()
            .then((res) => { setOrders(res) })
            .finally(() => { setIsPending(false) });
    }, []);



    return (
        <>


            <CartProvider>
                <Header />
                <StoreContainer>
                    <CartWrapper />
                    <OrdersContainer>
                        {isPending ? (
                            <>
                                <h1>Loading...</h1>
                            </>
                        ) : (

                            orders.map((order) => (
                                <OrderCard key={order.id} order={order} />
                            ))

                        )}
                    </OrdersContainer>

                </StoreContainer>
            </CartProvider>

        </>
    );
}
