import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useState } from "react"
import Input, { InputProps } from "../index"
import { MagicEye } from "./styles"

export default function InputMagicEye(props: InputProps) {
    const [type, setType] = useState<string>("password")
    const toggleMagicEye = function () {
        if (type === "password") setType("text")
        if (type === "text") setType("password")
    }

    return (
        <Input {...props} type={type}>
            <MagicEye type="button" onClick={toggleMagicEye}>
                {type === "password" &&
                    <Visibility />
                }
                {type === "text" &&
                    <VisibilityOff />
                }
            </MagicEye>
        </Input>
    )
}