import styled from "styled-components"

export const StoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 136px 16px 16px 16px;
    background-color: #FEFFFB;
`

export const OrdersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #FEFFFB;
`

