import { RefObject } from "react"
import { InputWrapper, Input_, Label } from "./styles"

export interface InputProps {
    name: string
    label?: string
    type?: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    min?: number
    children?: React.ReactNode
    innerRef?: RefObject<HTMLInputElement>
    value?: string | number
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    readOnly?: boolean
}

export default function Input(props: InputProps) {
    const randomId: string = Math.random().toString()

    return (
        <InputWrapper>
            {props.label && <Label htmlFor={randomId}>{props.label}</Label>}
            <Input_
                type={props.type ?? "text"}
                id={randomId}
                name={props.name}
                placeholder={props.placeholder}
                required={props.required}
                disabled={props.disabled}
                ref={props.innerRef}
                min={props.min}
                value={props.value}
                onChange={props.onChange}
                readOnly={props.readOnly}
            />
            {props.children}
        </InputWrapper>
    )
}