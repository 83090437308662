import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckoutStep from './CheckoutSteps';
import { sendCartOrder } from '../../@core/domain/carts';
import CartContext from '../../@core/context/CartContext';
import { HttpResponse } from '../../@core/gateway/http';
import { CartActionsButton } from '../Cart/styles';

const steps = ['Escolha o modo de entrega', 'Escolha o modo de pagamento', 'Concluir o envio'];

export default function HorizontalLinearStepper() {
    const useCart = React.useContext(CartContext);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [isLoading, setIsLoading] = React.useState(false);

    const [checkoutOk, setCheckoutOk] = React.useState(1);

    const isStepOptional = (step: number) => {
        return step === null;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = async () => {


        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep == 2) {
            try {
                await attemptSendCart();
            }
            catch {
                return;
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const attemptSendCart = async (): Promise<void> => {
        try {
            setIsLoading(true);

            // Faz copia do cart e adiciona o scheduledTo dentro de cart.information
            const cartToSend = {
                ...useCart.cart,
                information: {
                    ...useCart.cart.information,
                    scheduledTo: new Date().valueOf()
                }
            };

            console.log('cartToSend', cartToSend);
            const response: HttpResponse<null> = await sendCartOrder(cartToSend)
            if (!response.isSuccess) {
                alert(response.message);
                throw new Error("erro");
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Opcional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>

                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    {checkoutOk &&
                        <Typography sx={{ mt: 4, mb: 1, textAlign: 'center', }}>
                            Seu pedido foi enviado!
                        </Typography>
                    }
                    {!checkoutOk &&
                        <Typography sx={{ mt: 4, mb: 1, textAlign: 'center', }}>
                            Erro no envio!
                            Por favor, contate o suporte!
                            <CartActionsButton>
                                <a style={{ textDecoration: 'none', color: 'black' }} href="https://wa.me/+5585984129284" >Chat com o suporte</a>
                            </CartActionsButton>

                        </Typography>
                    }




                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Button onClick={handleReset}>Fechar</Button> */}
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <CheckoutStep step={activeStep} isLoading={isLoading}></CheckoutStep>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Voltar
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Pular
                            </Button>
                        )}
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Concluir' : 'Próximo'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}