import styled from "styled-components"

export const AccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 136px 16px 16px 16px;
    background-color: #FEFFFB;
`

export const ProductsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #FEFFFB;
`

