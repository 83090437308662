import styled from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    width: 640px;
    height: 240px;
    background: linear-gradient(0deg, rgba(82, 101, 0, 0.05), rgba(82, 101, 0, 0.05)), #FEFFFB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin-top: 16px;
    overflow: hidden;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    border-radius: 8px;
    flex: 1
`;

export const Image = styled.img`
    width: 220px;
    height: 240px;
    object-fit: cover;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
`

export const Title = styled.h2`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #241A00;
`

export const Headline = styled.div`
    display: flex;
    flex-direction: row;;
    width: 100%;
`

export const Description = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.25px;

    color: ${colors.text};
`

export const ActionButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;

    input {
        -moz-appearance: none;
        appearance: none;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
`

export const Input = styled.input`
    border-color: #77786A;
    border-radius: 4px;
    border-width: 1px;
    margin: 0 8px;
    background: transparent;
    text-align: center;
`