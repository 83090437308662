import { ProductType } from "../../@types";
import constants from "../../helpers/constants";
import { http } from "../gateway/http";

const PRODUCTS_PER_SECTION = 100

const PRODUCTS_FROM_SECTION_URL = `/sectionFunctions/section-api/get-section-products-without-subsection/${constants.STORE_ID}/${constants.SECTION_ID}?productsPerSection=${PRODUCTS_PER_SECTION}`;
const PRODUCTS_FROM_STORE = `/productFunctions/product-api/get-all-store-products/${constants.STORE_ID}`;

export const getProducts = async (): Promise<ProductType[]> => {
    const response = await http.get(PRODUCTS_FROM_STORE);
    
    const products: ProductType[] = response.data;
    const activeProducts = products.filter(product => product.active);
    
    return activeProducts;
}