import Icon from '@mui/material/Icon';
import { Container } from "./styles";


type IconProps = {
    name: string;
    subtitle?: string;
    active?: boolean;
    size?: "large" | "medium" | "small";
}

export default function IconComponent({ name, subtitle, active, size }: IconProps) {
    return (
        <div>
            <Container
                style={active ? {
                    backgroundColor: "#FFE169",
                }:{}}
            >
                <Icon fontSize={size}>{name}</Icon>
            </Container>
            <div>{subtitle}</div>
        </div>
    );
}