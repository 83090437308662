const colors = {
  white: '#FFF',
  lighter: 'rgba(117, 117, 117, 0.5)',
  light: '#DDDDDD',
  regular: '#888',
  dark: '#666',
  darker: '#333',
  black: '#000',
  gray: '#C0C0C0',
  app: '#F5F5F5',

  transparent: 'transparent',
  whiteTransparent: 'rgba(255, 255, 255, 0.5)',
  darkTransparent: 'rgba(0, 0, 0, 0.3)',
  blackOpacity: 'rgba(0, 0, 0, 0.5)',
  redOpacity: 'rgba(255, 99, 71, 0.8)',

  primary: '#556500',
  text: '#46483c',
  facebook: '#1877f2',
};

export default colors;
