import axios from 'axios';
import { getCurrentUserId, getRefreshToken } from '../domain/users';

const URL_DEV_LOCAL = 'http://localhost:5001/pglv-dev/us-central1';
const URL_DEV_FIREBASE = 'https://us-central1-pglv-dev.cloudfunctions.net'

export type HttpResponse<DataType> = {
    isSuccess: boolean
    message?: string,
    data?: DataType
}

interface ITokenResponse {
  idToken: string;
  refreshToken: string;
}

export const http = axios.create({
  // baseURL: URL_DEV_LOCAL,
  baseURL: URL_DEV_FIREBASE,
  headers: {
    'project-id': 'pglv_dev',
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (!error.response) {
      //Alert.alert('Erro de conexão! Verifique sua internet.');
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const userId = getCurrentUserId();      
      const refreshToken = getRefreshToken();      

      if (!userId) {
        console.log('sem usuario, - not implemented')

        return Promise.reject(error);
      }
      if (refreshToken) {
        const tokenResponse = await http.post<ITokenResponse>(
          '/userFunctions/user-api/refresh-token',
          {
            refreshToken,
          }
        );
        if (tokenResponse.status >= 200 && tokenResponse.status < 300) {
          const { idToken } = tokenResponse.data;
          localStorage.setItem('idToken', idToken);

          originalRequest.headers.Authorization = `Bearer ${tokenResponse.data.idToken}`;
          return axios(originalRequest);
        }
      }
    }

    return Promise.reject(error);
  }
);