import { AxiosError } from 'axios';
import { Cart } from '../../@types/cart';
import constants from '../../helpers/constants';
import { http, HttpResponse } from '../gateway/http';
import { getCurrentUserId } from './users';

/**
 * Retrieves the open cart for the current user.
 * @returns {Promise<HttpResponse<Cart | null>>} A Promise that resolves to the HTTP response containing the cart data or null.
 */
export const getUserOpenCart = (): Promise<HttpResponse<Cart | null>> => {
    return new Promise((resolve, reject) => {
        const GET_CART_URL = `cartFunctions/cart-api/get-user-open-cart/${constants.STORE_ID
            }/${getCurrentUserId()}`;
        http.get(GET_CART_URL)
            .then((response) => {
                if (response.status === 204) {
                    resolve({
                        isSuccess: true,
                        message: 'Carrinho vazio',
                    });
                    return;
                }

                resolve({
                    isSuccess: true,
                    data: response.data?.cart
                });
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof AxiosError) {
                    resolve({
                        isSuccess: false,
                        message:
                            error.response?.data?.message ??
                            'Ocorreu um erro inesperado',
                    });
                }

                reject(error);
            });
    });
};


/**
 * Sends the cart order to the server.
 * @param {Cart} cart - The cart object to be sent.
 * @returns {Promise<HttpResponse<null>>} A Promise that resolves to the HTTP response indicating the success or failure of the order.
 */
export const sendCartOrder = async (
    cart: Cart
): Promise<HttpResponse<null>> => {
    return new Promise((resolve, reject) => {
        const SAVE_CART_URL = 'cartFunctions/cart-api/save';

        http.post(SAVE_CART_URL, { cart: cart })
            .then((response) => {
                const responseData: string = response.data;

                resolve({
                    isSuccess: true,
                    message: 'Pedido feito com sucesso',
                });
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof AxiosError) {
                    resolve({
                        isSuccess: false,
                        message:
                            error.response?.data?.message ??
                            'Ocorreu um erro inesperado',
                    });
                }

                reject(error);
            });
    });
};
