import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NavContext from '../../../@core/context/NavContext';

export default function CenteredTabs() {
  const [value, setValue] = React.useState('agendados');
  const [value2, setValue2] = React.useState('dados');
  const { currentPage } = React.useContext(NavContext);
  const { setCurrentTab } = React.useContext(NavContext);
  const { setCurrentTab2 } = React.useContext(NavContext);
  const { updateCurrentTab } = React.useContext(NavContext);
  const { updateCurrentTab2 } = React.useContext(NavContext);



  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setCurrentTab(newValue);
    updateCurrentTab(newValue);
    console.log(newValue);
  };


  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
    setCurrentTab2(newValue);
    updateCurrentTab2(newValue);
    console.log(newValue);
  };


  if (currentPage == 'Produtos') {

    return (
      <Box sx={{ width: '100%' }}>
        <Tabs value={value} onChange={handleChange} centered>
          {/* <Tab label="Agendados" />
          <Tab label="Histórico" /> */}
        </Tabs>
      </Box>
    );
  }



  if (currentPage == 'Pedidos') {

    return (
      <Box sx={{ width: '100%' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Agendados" value='agendados'/>
          <Tab label="Histórico"  value='historico'/>
        </Tabs>
      </Box>
    );
  }


  if (currentPage == 'Conta') {

    return (
      <Box sx={{ width: '100%' }}>
        <Tabs value={value2} onChange={handleChange2} centered>
          <Tab label="Meus Dados" value='dados'/>
          <Tab label="Ajuda" value='ajuda'/>
        </Tabs>
      </Box>
    );
  }
  else {
    return (
      <></>
    )
  }






}