import React, { useEffect } from 'react';
import { CartProvider } from '../../@core/context/CartContext';
import { getProducts } from '../../@core/domain/products';
import { ProductType } from '../../@types';
import { CartWrapper } from '../../Components/Cart';
import Header from '../../Components/Header';
import { AccountContainer } from './styles';
import SignupForm from '../../Components/Signup';
import { getUserInformation } from '../../@core/domain/users';
import UserContext from '../../@core/context/UserContext';

export default function Products() {
    const [products, setProducts] = React.useState<ProductType[]>([]);
    const [isPending, setIsPending] = React.useState<boolean>(true);
    const useUser = React.useContext(UserContext);

    useEffect(() => {
        setIsPending(true);

        // carrega as informações do usuario no context
        getUserInformation()
            .then((res) => useUser.updateUser(res))
    }, []);

    return (
        <>
            <CartProvider>
                <Header />
                <CartWrapper />
                <AccountContainer>
                    <SignupForm buttonText='Salvar' />
                </AccountContainer>




            </CartProvider>
        </>
    );
}
