import styled from "styled-components";
import { BaseContainer } from "../../Components/BaseContainer/styles";
import { colors } from "../../styles";

export const LoginPage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;
`

export const LoginContainer = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    background: #EAECE2;
    max-width: 90%;
    margin: 10vh 0;
    width: 420px;
    box-sizing: border-box;
`

export const Logo = styled.img`
    width: 15%;
    min-width: 200px;
`

export const Agreement = styled.div`
    p {
        color: ${colors.text};

        a {
            color: ${colors.primary};
            font-weight: bold;
        }
    }
`