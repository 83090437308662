import styled from "styled-components"
import { colors } from "../../styles";

export const Container = styled.div`
    border-radius: 20px;
    padding: 4px 20px;
    color: ${colors.text};
`;

export const FirstContainer = styled.div`
    border-radius: 20px;
    padding: 4px 20px;
    color: ${colors.text};
`;

export const LastContainer = styled.div`
    border-radius: 20px;
    padding: 4px 20px;
    color: ${colors.text};
`;