import styled from "styled-components";


export const CartHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #F0F0F0 ;
`

export const CartProfile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #556500;
    border-radius: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #556500;
    width: 40px;
    height: 40px;
`

export const CartStatus = styled.div`
    display: flex;
    flex-direction: row;

`

export const CartHeaderButton = styled.button`
    background: rgba(31, 31, 31, 0.12);
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    border-color: transparent;

    &:active {
        background-color: #556500;
        color: #ffffff;
    };
`

export const CartContainer = styled.div`
    position: fixed;
    top: 140px;
    right: 0;
    width: 300px;
    margin: 16px;
    min-height: 100px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
`

export const CartPriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
`

export const CartPriceTrail = styled.div`
    display: flex;
    flex-direction: row;
`

export const CartTotalPrice = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`

export const CartActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 10px
`

export const CartActionsButton = styled.button`
    background: #FFE169;
    border-radius: 100px;
    color: #221B00;
    font-size: 16px;
    padding: 8px 8px;
    border-color: transparent;
`

export const CartActionsOutlineButton = styled.button`
    background: transparent;
    border-color: transparent;
    color: #556500;
    font-size: 16px;
`
