import { CartType2 } from "../../../@types"
import { Cart } from "../../../@types/cart"
import { Item } from "../../../@types/item"
import { maskMoney } from "../../../helpers/format"
import { CartContentCategory, CartContentCategoryName, CartContentCategoryProductList, CartContentImpl, Label, Number, Product } from "./styles"

type Props = {
    cart?: Cart | null
}

type CartGroupedItem = {
    category: string
    itemList: CartItem[]
}

type CartItem = {
    name: string
    quantity: number
    unity: string
    price: number
}

export default function CartContent(props: Props): JSX.Element {
    const emptyCart: JSX.Element = <CartContentImpl>
        <p>Seu carrinho está vazio :(</p>
    </CartContentImpl>

    if (!props.cart) return emptyCart

    const cartGroupedItemList: CartGroupedItem[] = groupByCategory(props.cart)
    if (!cartGroupedItemList.length) return emptyCart

    const sortedItemList: CartGroupedItem[] = sortAlphabetically(cartGroupedItemList)

    return (
        <CartContentImpl>
            {sortedItemList.map((cartCategoryItem: CartGroupedItem, i: number): JSX.Element => {
                return (
                    <CartContentCategory key={i}>
                        <CartContentCategoryName>
                            {cartCategoryItem.category}
                        </CartContentCategoryName>
                        <CartContentCategoryProductList>
                            {cartCategoryItem.itemList.map((item: CartItem, j: number): JSX.Element => {
                                return (
                                    <Product key={j}>
                                        <Label>{item.name}</Label>
                                        <Number>{item.quantity}{item.unity}</Number>
                                        <Number>{maskMoney(item.price)}</Number>
                                    </Product>
                                )
                            })}
                        </CartContentCategoryProductList>
                    </CartContentCategory>
                )
            })}
        </CartContentImpl>
    )
}

export function groupByCategory(cart: Cart): CartGroupedItem[] {
    const cartItemList: CartGroupedItem[] = []
    cart.items.forEach((item: Item) => {
        let categoryIndex: number = cartItemList.findIndex((cartItem: CartGroupedItem) => {
            return cartItem.category == item.product.category
        })
        if (categoryIndex === -1) {
            categoryIndex = cartItemList.push({
                category: item.product.category,
                itemList: []
            })

            categoryIndex--
        }

        cartItemList[categoryIndex].itemList.push({
            name: item.product.name,
            quantity: item.qty,
            unity: item.product.unity,
            price: item.value ?? item.product.value
        })
    })

    return cartItemList
}

export function groupByCategory2(cart: CartType2): CartGroupedItem[] {
    const cartItemList: CartGroupedItem[] = []
    cart.items.forEach((item: Item) => {
        let categoryIndex: number = cartItemList.findIndex((cartItem: CartGroupedItem) => {
            return cartItem.category == item.product.category
        })
        if (categoryIndex === -1) {
            categoryIndex = cartItemList.push({
                category: item.product.category,
                itemList: []
            })

            categoryIndex--
        }

        cartItemList[categoryIndex].itemList.push({
            name: item.product.name,
            quantity: item.qty,
            unity: item.product.unity,
            price: item.value ?? item.product.value
        })
    })

    return cartItemList
}

export function sortAlphabetically(itemList: CartGroupedItem[]): CartGroupedItem[] {
    const itemListWithCategorySorted: CartGroupedItem[] = itemList.sort((a: CartGroupedItem, b: CartGroupedItem): number => {
        if (a.category > b.category) return 1
        if (a.category < b.category) return -1
        return 0
    })

    const itemListFullSorted: CartGroupedItem[] = itemListWithCategorySorted.map((item: CartGroupedItem): CartGroupedItem => {
        item.itemList = item.itemList.sort((a: CartItem, b: CartItem) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
        })

        return item
    })

    return itemListFullSorted
}
