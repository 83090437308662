
import { Add, Remove } from "@mui/icons-material";
import React, { useContext } from "react";
import { useEffect } from 'react';

import { RefObject, useRef, useState } from "react";
import CartContext from "../../@core/context/CartContext";
import { CartType2 } from "../../@types";
import Button from "../Button";
import Input from "../Form/Input";
import { ActionButtonsWrapper, Container, Description, Headline, Image, InfoContainer, Title } from "./styles";
import { CartContentCategory, CartContentCategoryName, CartContentCategoryProductList, CartContentImpl, Label, Number, Product } from "./styles"
import { groupByCategory2, sortAlphabetically } from '../Cart/CartContent'
import { maskMoney, maskItemQty } from "../../helpers/format"
import {
    CartActionsButton,
    CartActionsContainer,
    CartActionsOutlineButton,
    CartContainer,
    CartHeader,
    CartPriceContainer,
    CartPriceTrail,
    CartProfile,
    CartTotalPrice
} from './styles';
import NavContext from "../../@core/context/NavContext";

type OrderCardProps = {
    order: CartType2
}

type CartGroupedItem = {
    category: string
    itemList: CartItem[]
}

type CartItem = {
    name: string
    quantity: number
    unity: string
    price: number
}


export default function OrderCard({ order }: OrderCardProps) {

    const { currentTab } = React.useContext(NavContext);

    const cartGroupedItemList: CartGroupedItem[] = groupByCategory2(order)

    const sortedItemList: CartGroupedItem[] = sortAlphabetically(cartGroupedItemList)

    const [itemsCounter, setItemsCounter] = React.useState<number>(0)
    const [value, setValue] = React.useState<number>(0)


    useEffect(() => {
        let totalQuantity = 0;
        let totalValue = 0;
        for (const item of order.items) {
            totalQuantity += item.qty;
            totalValue += item.value;
        }

        setItemsCounter(totalQuantity);
        setValue(totalValue);
    }, [order])


    return (
        <div>
            {((currentTab == 'agendados' && (order.status == 'UNSTARTED' || order.status == 'COLECTED' || order.status == 'READY')) ||
                ((currentTab == 'historico') && (order.status == 'DELIVERED' || order.status == 'CANCELLED'))) &&


                < Container >
                    <CartContainer>
                        <CartHeader>
                            <p>#{order.id?.slice(15)}</p>
                            <p>{order.statusTitle}</p>

                        </CartHeader>

                        <CartContentImpl>
                            {sortedItemList.map((cartCategoryItem: CartGroupedItem, i: number): JSX.Element => {
                                return (
                                    <CartContentCategory key={i}>
                                        <CartContentCategoryName>
                                            {cartCategoryItem.category}
                                        </CartContentCategoryName>
                                        <CartContentCategoryProductList>
                                            {cartCategoryItem.itemList.map((item: CartItem, j: number): JSX.Element => {
                                                return (
                                                    <Product key={j}>
                                                        <Label>{item.name}</Label>
                                                        <Number>{item.quantity}{item.unity}</Number>
                                                        <Number>{maskMoney(item.price)}</Number>
                                                    </Product>
                                                )
                                            })}
                                        </CartContentCategoryProductList>
                                    </CartContentCategory>
                                )
                            })}
                        </CartContentImpl>
                        <CartPriceContainer>
                            <CartPriceTrail>Total </CartPriceTrail>
                            <CartTotalPrice>
                                <p>{maskItemQty(itemsCounter)} itens</p>
                                <p>{maskMoney(value)}</p>
                            </CartTotalPrice>
                        </CartPriceContainer>
                        <CartActionsContainer>
                            <CartActionsButton>
                                <a style={{ textDecoration: 'none', color: 'black' }} href="https://wa.me/+5585984129284" >Chat com o suporte</a>
                            </CartActionsButton>
                        </CartActionsContainer>
                    </CartContainer>
                </Container >

            }

        </div>

    )
}