import React from "react";
import Icon from "../Icon";
import {
    Link
} from "react-router-dom"

export default function BackButton() {
    return (
        <div>

            <a href="https://superbarn.com.br/">
                <Icon name="arrow_back" />

            </a>
        </div>
    );
}