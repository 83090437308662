import { Cart } from "../../@types/cart";
import { Coupom } from "../../@types/coupom";
import constants from "../../helpers/constants";
import { http } from "../gateway/http";
import { getCurrentUserId } from "./users";


export const validateCoupon = async (counponCode: string, cart: Cart): Promise<Coupom> => {

    const COUPON_VERIFY_URL = `couponFunctions/coupon-api/isValid/${counponCode}`;
    const userId = getCurrentUserId();
    // testando
    // const storeId = '-L3iuWigJX6nUIpWMv21';
    // const userId = 'l0DKblQ7WMSHBXTOrhFIsYGAzGN2';

    let coupon = null;

    const response = await http.post(
        COUPON_VERIFY_URL,
        { cart },
        {
            params: {
                storeId: constants.STORE_ID,
                userId: userId,
                // storeId,
                // userId,
            }
        }).catch((e) => {
            if (e.response.data.message) {
                alert(e.response.data.message);
            }
            else {
                alert('Não foi possivel validar o cupom.');
            }
        });

    if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status !== 200
    ) {
        alert(response.data.message);
    } else if (response?.data.couponCode) {
        coupon = response.data;
    }
    console.log('coupon response', response);
    return coupon;
}