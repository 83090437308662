import styled from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    width: 640px;
    // height: 240px;
    // background: linear-gradient(0deg, rgba(82, 101, 0, 0.05), rgba(82, 101, 0, 0.05)), #FEFFFB;
    background: #FFFFFF;
    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin-top: 16px;
    // overflow: hidden;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    border-radius: 8px;
    flex: 1
`;

export const Image = styled.img`
    width: 220px;
    height: 240px;
    object-fit: cover;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
`

export const Title = styled.h2`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #241A00;
`

export const Headline = styled.div`
    display: flex;
    flex-direction: row;;
    width: 100%;
`

export const Description = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.25px;

    color: ${colors.text};
`

export const ActionButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;

    input {
        -moz-appearance: none;
        appearance: none;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
`

export const Input = styled.input`
    border-color: #77786A;
    border-radius: 4px;
    border-width: 1px;
    margin: 0 8px;
    background: transparent;
    text-align: center;
`

export const CartContentImpl = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 24px 16px;
`

export const CartContentCategory = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: 100%
`

export const CartContentCategoryName = styled.h4`
    margin: 0;
    line-height: 1;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
`

export const Label = styled.span`
    text-align: left;
`

export const Number = styled.span`
    text-align: right;
`

export const Items = styled.span`
    text-align: right;
    width: 20%;
    justify-content: end;
`

export const Product = styled.li`
    display: inline-flex;
    justify-content: space-between;
    font-size: .8rem;
    column-gap: 12px;

    ${Label}:nth-child(1) {
        flex: 1 1 0;
    }
`

export const CartContentCategoryProductList = styled.ul`
    display: inline-flex;
    flex-direction: column;
    row-gap: 8px;
    list-style: none;
    padding: 0;
`

export const CartHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #F0F0F0 ;
`

export const CartProfile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #556500;
    border-radius: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #556500;
    width: 40px;
    height: 40px;
`

export const CartHeaderButton = styled.button`
    background: rgba(31, 31, 31, 0.12);
    border-radius: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    border-color: transparent;

    &:active {
        background-color: #556500;
        color: #ffffff;
    };
`

export const CartContainer = styled.div`
    // position: fixed;
    top: 140px;
    right: 0;
    width: 100%;
    margin: 16px;
    min-height: 100px;
    // background: #ffffff;
    background: linear-gradient(0deg, rgba(82, 101, 0, 0.05), rgba(82, 101, 0, 0.05)), #FEFFFB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
`

export const CartPriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
`

export const CartPriceTrail = styled.div`
    display: flex;
    flex-direction: row;

`

export const CartTotalPrice = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`



export const CartActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 16px 8px;
`

export const CartActionsButton = styled.button`
    // background: #FFE169;
    border-radius: 100px;
    color: #221B00;
    font-size: 16px;    
    padding: 8px 16px;
    border-color: transparent;
`

export const CartActionsOutlineButton = styled.button`
    background: transparent;
    border-color: transparent;
    color: #556500;
    font-size: 16px;
`
