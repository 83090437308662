import styled from "styled-components";

export const MagicEye = styled.button`
    position: absolute;
    right: 12px;
    margin: auto;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    cursor: pointer;
`