import { FacebookRounded } from "@mui/icons-material";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import CartContext from "../../@core/context/CartContext";
import UserContext from "../../@core/context/UserContext";
import { isLogged, signUp, updateUserInDB } from "../../@core/domain/users";
import { HttpResponse } from "../../@core/gateway/http";
import { AddressType } from "../../@types";
import Button from "../Button";
import Input from "../Form/Input";
import InputMagicEye from "../Form/Input/InputMagicEye";
import { Form } from "../Form/styles";
import { Agreement, SignupContainer, SignupPage, Logo } from "./styles";

export default function AddressForm() {

    const [sendingForm, setSendingForm] = useState<boolean>(false)
    const [cep, setCEP] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('0');
    const [complement, setComplement] = useState('');
    const [reference, setReference] = useState('');
    const [borough, setBorough] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const useUser = React.useContext(UserContext);
    const useCart = React.useContext(CartContext);

    useEffect(() => {
        const { user } = useUser;
        const { cart } = useCart;

        console.log('cart context', cart);

        if (cart.information && cart.information.address) {
            updateFormFields(cart.information.address);
        } else {
            console.log('nao existe address')
            // Pega as informações do usuario no context e passa para o formulario
            if (user.information?.addressList?.length &&
                user.information?.addressList?.length > 0) {
                updateFormFields(user.information.addressList[0]);
                useCart.updateAddress(user.information.addressList[0]);
            }
        }
    }, []);

    // any pois ha divergencia entre os tipos AddressType do user e AddressInformation do cart
    function updateFormFields({ cep, street, number, complement, reference, borough, city, state }: any) {
        setCEP(cep);
        setStreet(street);
        setNumber(number);
        setCity(city);
        setState(state);
        if (complement) setComplement(complement);
        if (reference) setReference(reference);
        // workaround para diferentes tipos de bairro
        if (typeof borough === "string") {
            setBorough(borough);
        } else if (typeof borough === "object") {
            setBorough(borough.name);
        }
    }

    const attemptSaveAddress = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        try {
            setSendingForm(true);
            event.preventDefault();
            const { user } = useUser;

            const userToUpdate = { ...user };

            const addressToSave = {
                street,
                cep,
                city,
                state,
                complement,
                number,
                borough,
                reference,
                "alias": "Teste ",
                "type": "delivery",
                "isMain": true
            };

            if (userToUpdate.information?.addressList) {
                if (userToUpdate.information?.addressList[0]) {
                    userToUpdate.information.addressList[0] = addressToSave;
                } else {
                    userToUpdate.information.addressList.push(addressToSave);
                }
            }

            await updateUserInDB(userToUpdate)
                .then(() => useUser.updateUser(userToUpdate));

        } catch (error) {
            console.error(error)
            alert(`Ocorreu um erro inesperado. Contate o suporte.\nDetalhes:${JSON.stringify(error)}`)
        } finally {
            setSendingForm(false)
        }
    }

    function handleCEPFormat(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;

        // Remove non-digit characters from the input value
        const strippedValue = value.replace(/\D/g, '');

        // Format the value as XXXXX-XXX
        const formattedValue = strippedValue.replace(/^(\d{5})(\d{0,3}).*/, '$1-$2');

        // Update the state with the formatted value
        setCEP(formattedValue);
        useCart.updateAddress({cep: formattedValue});
    }
    function handleStreetChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setStreet(value);
        useCart.updateAddress({street: value});
    }
    function handleNumberChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setNumber(value);
        useCart.updateAddress({number: value});
    }
    function handleComplementChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setComplement(value);
        useCart.updateAddress({complement: value});
    }
    function handleReferenceChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setReference(value);
        useCart.updateAddress({reference: value});
    }
    function handleBoroughChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setBorough(value);
        useCart.updateAddress({borough: value});
    }
    function handleCityChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setCity(value);
        useCart.updateAddress({city: value});
    }
    function handleStateChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setState(value);
        useCart.updateAddress({state: value});
    }

    return (
        <SignupPage>
            <SignupContainer>
                <Form onSubmit={attemptSaveAddress}>
                    <Input label="CEP" type="name" name="name" placeholder="xxxxxx-xxx" onChange={handleCEPFormat} value={cep} required={true} disabled={sendingForm} />
                    <Input label="Endereço" type="endereço" name="endereço" placeholder="Ex.: Rua A " value={street} onChange={handleStreetChange} required={true} disabled={sendingForm} />
                    <Input label="Número" type="número" name="número" placeholder="ex.: 200" value={number} onChange={handleNumberChange} required={false} disabled={sendingForm} />
                    <Input label="Complemento" type="complemento" name="complemento" placeholder="ex.: ap 601" value={complement} onChange={handleComplementChange} required={false} disabled={sendingForm} />
                    <Input label="Ponto de Referência" type="ponto de Referência" name="ponto de Referência" placeholder="ponto de referência próximo" value={reference} onChange={handleReferenceChange} required={false} disabled={sendingForm} />
                    <Input label="Bairro" type="bairro" name="bairro" placeholder="ex.: Centro" value={borough} onChange={handleBoroughChange} required={true} disabled={sendingForm} />
                    <Input label="Cidade" type="cidade" name="cidade" placeholder="ex.: Fortaleza" value={city} onChange={handleCityChange} required={true} disabled={sendingForm} />
                    <Input label="Estado" type="estado" name="estado" placeholder="ex: CE" required={true} value={state} onChange={handleStateChange} disabled={sendingForm} />
                    <Button type="submit" color="primary" style="outlined" disabled={sendingForm}>
                        {!sendingForm ? 'Salvar' : 'Aguarde...'}
                    </Button>
                </Form>
            </SignupContainer>
        </SignupPage>
    )
}