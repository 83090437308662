import React from 'react';
import { Icon } from '@mui/material';
import CenteredTabs from './Tabs';
import BackButton from '../BackButton';
import StoreProfile from '../StoreProfile';
import {
  CartContainer,
  CartContainerOpened,
  Header,
  Nav,
  NavLeft,
  Title,
  TitleContainer,
} from './styles';
import CartContext from '../../@core/context/CartContext';
import NavContext from '../../@core/context/NavContext';
import SimpleBottomNavigation from '../BottomNavigation'

const CartButton = () => {
  const { isOpen, toggleCart } = React.useContext(CartContext);
  if (isOpen) {
    return (
      <CartContainerOpened onClick={toggleCart}>
        <Icon color={'secondary'}>shopping_basket</Icon>
      </CartContainerOpened>
    );
  }
  return (
    <CartContainer onClick={toggleCart}>
      <Icon color={'inherit'}>shopping_basket</Icon>
    </CartContainer>
  );
};

export default function HeaderContainer() {
  const { currentPage, currentPage2 } = React.useContext(NavContext);

  return (
    <Header>
      <Nav>
        <NavLeft>
          <BackButton />
          <StoreProfile />
        </NavLeft>
        <SimpleBottomNavigation />
      </Nav>
      <TitleContainer>
        <div>
          <Title>
            {currentPage}
            {/* {currentPage2.title} */}
          </Title>

        </div>
        <div>
          <CenteredTabs />

        </div>
        <div>
          <CartButton />

        </div>
      </TitleContainer>
    </Header>
  );
}
