import React, { useContext } from 'react';
import Button from '../../Button';
import { OptionsRow, TotalsRow } from './styles';
import CartContext from '../../../@core/context/CartContext';
import AddressForm from '../../AddressForm';
import CardForm from '../../CardForm';
import { maskMoney } from '../../../helpers/format';
import { Loading } from '../../Loading';

type Props = {
    step: number,
    isLoading: boolean
}


export default function CheckoutStep(props: Props) {

    const useCart = React.useContext(CartContext);

    const [deliveryMode, setDeliveryMode] = React.useState('');
    const handleDeliveryMode = (deliveryMode: string) => {
        setDeliveryMode(deliveryMode);

        useCart.setIsDeliveryMethod(deliveryMode === 'delivery');
    };

    const [paymentMode, setPaymentMode] = React.useState('');
    const handlePayment = (paymentSelected: string) => {
        setPaymentMode(paymentSelected);

        useCart.setPaymentMethod(paymentSelected);
    };

    const [address, setAddress] = React.useState('endereço...');
    const handleAddress = (a: string) => {
        setAddress(a);
    };

    const [storeAddress, setStoreAddress] = React.useState('endereço da loja...');
    const handleStoreAddress = (a: string) => {
        setStoreAddress(a);
    };

    const [cardLastDigits, setcardLastDigits] = React.useState('...4444');
    const handleCardLastDigits = (a: string) => {
        setcardLastDigits(a);
    };

    const { value } = React.useContext(CartContext);

    if (props.step == 0) {
        return (
            <>
                <OptionsRow>
                    <Button color='primary' style='text' onClick={() => handleDeliveryMode('delivery')}>Delivery</Button>

                    <Button color='primary' style='text' onClick={() => handleDeliveryMode('drivethru')} disabled={true}>Drive Thru</Button>
                </OptionsRow>
                {deliveryMode == 'delivery' &&
                    <div>
                        <AddressForm />
                    </div>
                }

            </>

        )
    }


    if (props.step == 1) {
        return (
            <div>
                <OptionsRow>
                    <Button color='primary' style='text' onClick={() => handlePayment('dinheiro')} disabled={true}>Dinheiro</Button>

                    <Button color='primary' style='text' onClick={() => handlePayment('maquineta')} >Cartão Maquineta</Button>
                </OptionsRow>

                <OptionsRow>
                    <Button color='primary' style='text' onClick={() => handlePayment('online')} disabled={true}>Cartão Online</Button>

                    <Button color='primary' style='text' onClick={() => handlePayment('boleto')} disabled={true}>Boleto</Button>
                </OptionsRow>
                {paymentMode == 'dinheiro' &&
                    <div>
                        formulario de troco
                    </div>
                }
                {paymentMode == 'maquineta' &&
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        Será enviada uma maquineta na entrega
                    </div>
                }
                {paymentMode == 'online' &&
                    <div>
                        <CardForm />
                    </div>
                }
                {paymentMode == 'boleto' &&
                    <div>
                        informativo do vencimento
                    </div>
                }
            </div>

        )

    }

    if (props.step == 2) {
        console.log('carrinho final:', useCart.cart);
        return (
            <div>
                {props.isLoading ?
                    <Loading />
                    :
                    <>
                        <h3>Entrega</h3>
                        {deliveryMode == 'delivery' &&
                            <div>
                                <p>Receber no endereço:</p>
                                <p>
                                    {
                                        `${useCart.cart.information?.address?.street}, 
                                        ${useCart.cart.information?.address?.number} - 
                                        ${useCart.cart.information?.address?.borough} -
                                        ${useCart.cart.information?.address?.city}`
                                    }
                                </p>
                            </div>
                        }
                        {deliveryMode == 'drivethru' &&
                            <div>
                                <p>retirar em:</p>

                                <p>{storeAddress}</p>
                            </div>
                        }
                        <h3>Pagamento</h3>
                        {paymentMode == 'dinheiro' &&
                            <div>
                                <p>Pagamento em Dinheiro</p>
                                {/* <p>Troco para: {cardLastDigits}</p> */}
                            </div>
                        }
                        {paymentMode == 'maquineta' &&
                            <div>
                                <p>Pagamento na Maquineta</p>
                                {/* <p>Cartão de bandeira: {cardLastDigits}</p> */}
                            </div>
                        }
                        {paymentMode == 'online' &&
                            <div>
                                <p>Pagamento Online</p>
                                {/* <p>Cartão com final: {cardLastDigits}</p> */}
                            </div>
                        }
                        {paymentMode == 'boleto' &&
                            <div>
                                <p>Pagamento em boleto</p>
                                {/* <p>Com vencimento: {cardLastDigits}</p> */}
                            </div>
                        }
                        <TotalsRow>
                            <h3>Valor do Pedido</h3>
                            <h3>{maskMoney(value)}</h3>

                        </TotalsRow>
                    </>
                }
            </div>

        )

    }

    else {
        return (
            <p> passo X </p>
        )
    }

}