export const maskMoney = (value: number): string => {
    const moneyFormatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits:2,
    });

    const result = moneyFormatter.format(value);
    return `R$ ${result}`;
};

export const maskItemQty = (value: number): string => {
    const moneyFormatter = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 0,
    });

    const result = moneyFormatter.format(value);
    return `${result}`;
};