import React, { useCallback } from 'react';
import CartContext from '../../@core/context/CartContext';
import { maskMoney } from '../../helpers/format';
import CartContent from './CartContent';
import BasicModal from '../Modal';
import {
    CartActionsButton,
    CartActionsContainer,
    CartActionsOutlineButton,
    CartContainer,
    CartHeader,
    CartPriceContainer,
    CartPriceTrail,
    CartProfile,
    CartTotalPrice
} from './styles';
import Input from '../Form/Input';
import Button from '../Button';
import { validateCoupon } from '../../@core/domain/coupon';
import { Loading } from '../Loading';
import IconComponent from '../Icon';

export function CartWrapper() {
    const [isFetchingCoupon, setIsFetchingCoupon] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isShowingCouponInput, setIsShowingCouponInput] = React.useState<boolean>(false);
    const [coupon, setCoupon] = React.useState('');
    const cartContext = React.useContext(CartContext);

    const handleShowCoupon = useCallback(() => {
        setIsShowingCouponInput((isShowing) => !isShowing);
    }, []);

    function handleVerifyCoupon() {
        console.log('validating coupon');
        setIsFetchingCoupon(true);
        validateCoupon(coupon, cartContext.cart)
            .then((res) => {
                if (res) {
                    console.log('updating coupon', res);
                    cartContext.applyCoupom(res);
                    setIsShowingCouponInput(false);
                }
            })
            .finally(() => { setIsFetchingCoupon(false) });
    }
    if (!cartContext.isOpen) return null;
    return (
        <CartContainer>
            <CartHeader>
                {cartContext.itemsCounter == 0 &&
                    <>
                        <CartProfile>V</CartProfile>
                        <p>carrinho vazio</p>
                    </>
                }
                {cartContext.itemsCounter > 0 &&
                    <>
                        <CartProfile>A</CartProfile>
                        <p>carrinho aberto</p>
                    </>
                }



                <BasicModal buttonText={'Enviar'} modalType={'checkout'}></BasicModal>

            </CartHeader>
            <CartContent cart={cartContext.cart} />
            <CartPriceContainer>
                {
                    cartContext.cart.information?.coupons ?
                        <>
                            <CartPriceTrail>
                                <p>
                                    Cupom: {cartContext.cart.information?.coupons[0]}
                                </p>
                            </CartPriceTrail>
                            <CartTotalPrice>
                                <Button style='text' color='primary' onClick={cartContext.removeCoupon}>
                                    <IconComponent name='close' />
                                </Button>
                            </CartTotalPrice>
                        </>
                        :
                        <></>
                }
            </CartPriceContainer>
            <CartPriceContainer>
                {
                    cartContext.cart.information?.discount ?
                        <>
                            <CartPriceTrail>Desconto</CartPriceTrail>
                            <CartTotalPrice>
                                <p>{maskMoney(cartContext.cart.information.discount * -1)}</p>
                            </CartTotalPrice>
                        </>
                        :
                        <></>
                }
            </CartPriceContainer>
            <CartPriceContainer>
                <CartPriceTrail>Total estimado</CartPriceTrail>
                <CartTotalPrice>
                    <p>{cartContext.itemsCounter} itens</p>
                    <p>{maskMoney(cartContext.value)}</p>
                </CartTotalPrice>
            </CartPriceContainer>
            <CartActionsContainer>
                {
                    isShowingCouponInput ?
                        <>
                            {
                                isFetchingCoupon ?
                                    <div style={{ width: '100%' }}>
                                        <Loading />
                                    </div>
                                    :
                                    <>
                                        <div style={{ flex: 2 }}>
                                            <Input name='coupon' label='Cupom' value={coupon} onChange={(event) => setCoupon(event.target.value)} />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <Button color='primary' style='filled' onClick={handleVerifyCoupon}>Inserir</Button>
                                        </div>
                                    </>
                            }

                        </>
                        :
                        <>
                            <div style={{ flex: 1 }}>
                                <Button color='primary' style='text' disabled={cartContext.itemsCounter==0 ? true : false }>Limpar carrinho</Button>
                            </div>
                            <div style={{ flex: 1 }}>
                                <CartActionsButton onClick={handleShowCoupon}>Inserir cupom</CartActionsButton>
                            </div>

                        </>
                }
            </CartActionsContainer>
        </CartContainer >
    );
}
