import styled from "styled-components";
import { colors } from "../../styles";

export const Button_ = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: .9rem;
    line-height: .9rem;
    padding: 16px;
    border-radius: 32px;
    border: none;
    outline: none;
    transition: background .2s ease;
    cursor: pointer;

    svg {
        margin: -6px 0;
        width: 24px;
    }
`

export const FilledButton = styled(Button_)`
    color: ${props => props.color === "primary" ? "#ffffff" : "#000000"};
    background-color: ${props => colors[props.color] ?? "#000000"};

    &:hover {
        background-color: #708500;
    },
    &:focus,
    &:active {
        background-color: #556500;
    }

    &:disabled {
        background-color: #e4e4e4;
        color: #aca89e;
    }
`

export const TextButton = styled(Button_)`
    color: ${props => colors[props.color] ?? "#000000"};
    background: none;

    &:hover {
        background-color: #eef4ea;
    }

    &:focus,
    &:active {
        background-color: #eef4ea;
    }

    &:disabled {
        color: #aca89e;
    }
`

export const OutlinedButton = styled(TextButton)`
    border: ${props => colors[props.color] ?? "#000000"} solid 2px;
    background-color: ${props => props.color === "facebook" ? '#ffffff' : 'transparent'};

    &:disabled {
        border-color: #aca89e;
        pointer-events: none;
    }
`