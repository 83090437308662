import styled from "styled-components";


export const OptionsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #F0F0F0 ;
`
export const TotalsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16 16 0;
    border-bottom: 1px solid #F0F0F0 ;
`