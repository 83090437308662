import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { AccountCircle, ReceiptLong, Store } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import NavContext from '../../@core/context/NavContext';
import IconComponent from '../Icon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function SimpleBottomNavigation() {
  const { currentPage, updateCurrentPage } = React.useContext(NavContext);
  const [value, setValue] = React.useState(currentPage);
  

  return (
    <Box sx={{ width: 500 }}>
      <BottomNavigation
        sx={{ bgcolor: 'transparent' }}
        showLabels
        value={value}
        onChange={(event, newValue) => {

          console.log('Value: ' + value);
          setValue(newValue);
          console.log('newValue: ' + newValue);
          updateCurrentPage(newValue);
          // updateCurrentPage2({id: newValue, title: label});

        }}
      >
        <BottomNavigationAction label="Produtos" icon={<IconComponent name="store" active={value === 'Produtos'} />} value='Produtos' component={Link} to="/products" />
        <BottomNavigationAction label="Pedidos" icon={<IconComponent name="receiptlong" active={value === 'Pedidos'} />} value='Pedidos' component={Link} to="/orders" />
        <BottomNavigationAction label="Conta" icon={<IconComponent name="personpin" active={value === 'Conta'} />} value='Conta' component={Link} to="/account" />
      </BottomNavigation>
    </Box>
  );

}
