import React from "react";
import { ImageContainer } from "./styles";

export default function StoreProfile() {
    return (
        <ImageContainer>
            <img src="logo-super-barn.png" alt="logo" width={56} height={56} />
        </ImageContainer>
    );
}
