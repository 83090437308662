import React from "react";

export type NavContextType = {
    currentPage: string,
    currentPage2: Page,

    updateCurrentPage: (newValue: string) => void,
    updateCurrentPage2: (newValue: Page) => void,

    currentTab: string,
    currentTab2: string,

    updateCurrentTab: (newValue:string) => void,
    updateCurrentTab2: (newValue:string) => void,

    setCurrentTab: (newValue:string) => void,
    setCurrentTab2: (newValue:string) => void,

}

export type Page = {
    id: string,
    title: string,
}

const NavContext = React.createContext<NavContextType>({
    currentPage: 'Produtos',
    currentPage2: { id: 'Produtos', title: 'Produtos' },

    updateCurrentPage: () => { throw new Error("Método não implementado") },
    updateCurrentPage2: () => { throw new Error("Método não implementado") },

    currentTab: 'agendados',
    currentTab2: 'dados',
    
    updateCurrentTab:  () => { throw new Error("Método não implementado") },
    updateCurrentTab2:  () => { throw new Error("Método não implementado") },
    
    setCurrentTab: () => { throw new Error("Método não implementado") },
    setCurrentTab2: () => { throw new Error("Método não implementado") },

})




// const pegarPropriedade = (propriedade) {

//     const pages : object = [
//         { id: '/products', title: 'Produtos' },
//         { id: '/orders', title: 'Pedidos' },
//         { id: '/account', title: 'Conta' }
//     ];

//     const obterPropriedade = (objeto: Page, propriedade: keyof Page):string => {
//         return objeto[propriedade];
//     };

//     return (
//         <div>
//             {pages.map((page) => (
//                 <div key={page.id}>
//                     <p>id: {page.id}</p>
//                     <p>Título: {obterPropriedade(page, 'title')}</p>
//                 </div>
//             ))}
//         </div>
//     );

// };


export type NavProviderProps = {
    children: React.ReactNode
}

export const NavProvider: React.FC<NavProviderProps> = ({ children }) => {
    const [currentPage, setCurrentPage] = React.useState<string>('Produtos')
    const [currentPage2, setCurrentPage2] = React.useState<Page>({ id: 'Produtos', title: 'Produtos' })

    const [currentTab, setCurrentTab] = React.useState<string>('agendados')
    const [currentTab2, setCurrentTab2] = React.useState<string>('dados')

    const updateCurrentPage = (newPage: string) => {

        setCurrentPage(newPage);
    }

    const updateCurrentPage2 = (newPage2: Page) => {

        setCurrentPage2(newPage2);
    }

    const updateCurrentTab = (newValue: string) => {

        setCurrentTab(newValue);
    }
    const updateCurrentTab2 = (newValue: string) => {

        setCurrentTab2(newValue);
    }

    return (
        <NavContext.Provider value={{ currentPage, currentPage2, updateCurrentPage, updateCurrentPage2, currentTab, currentTab2, updateCurrentTab ,updateCurrentTab2 , setCurrentTab, setCurrentTab2}} >
            {children}
        </NavContext.Provider>
    );
};


export default NavContext;