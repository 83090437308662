// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDA7w5XFyHORHpc7Qer9WojTcBaKX9leUA",
  authDomain: "pglv-dev.firebaseapp.com",
  databaseURL: "https://pglv-dev.firebaseio.com",
  projectId: "pglv-dev",
  storageBucket: "pglv-dev.appspot.com",
  messagingSenderId: "757310145972",
  appId: "1:757310145972:web:511a776a7755ff61c19ee1",
  measurementId: "G-4WDSHMEX8S"
};

// Initialize Firebase
export const initFirebase = () => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
}