import React, { useEffect } from 'react';
import { CartProvider } from '../../@core/context/CartContext';
import UserContext from '../../@core/context/UserContext';
import { getProducts } from '../../@core/domain/products';
import { getUserInformation } from '../../@core/domain/users';
import { ProductType } from '../../@types';
import { CartWrapper } from '../../Components/Cart';
import Header from '../../Components/Header';
import ProductCard from '../../Components/ProductCard';
import { ProductsContainer, StoreContainer } from './styles';

export default function Products() {
    const [products, setProducts] = React.useState<ProductType[]>([]);
    const [isPending, setIsPending] = React.useState<boolean>(true);
    const useUser = React.useContext(UserContext);

    useEffect(() => {
        setIsPending(true);
        getProducts()
            .then((res) => setProducts(res))
            .finally(() => setIsPending(false));

        // carrega as informações do usuario no context
        getUserInformation()
            .then((res) => useUser.updateUser(res))
    }, []);

    return (
        <>
            <CartProvider>
                <Header />
                <StoreContainer>
                    <CartWrapper />
                    <ProductsContainer>
                        {isPending ? (
                            <>
                                <h1>Loading...</h1>
                            </>
                        ) : (
                            products.map((product) => (
                                <ProductCard key={product.id} product={product} />
                            ))
                        )}
                    </ProductsContainer>
                </StoreContainer>
            </CartProvider>
        </>
    );
}
