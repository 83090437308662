import { FacebookRounded } from "@mui/icons-material";
import path from "node:path/win32";
import React, { FormEvent, useEffect, useState } from "react";
import UserContext from "../../@core/context/UserContext";
import { isLogged, signUp, updateUserInDB } from "../../@core/domain/users";
import { HttpResponse } from "../../@core/gateway/http";
import { UserType } from "../../@types";
import Button from "../Button";
import Input from "../Form/Input";
import InputMagicEye from "../Form/Input/InputMagicEye";
import { Form } from "../Form/styles";
import { Agreement, SignupContainer, SignupPage, Logo } from "./styles";

type Props = {
    buttonText: string,
}

export default function SignupForm(props: Props) {

    const useUser = React.useContext(UserContext);
    const [buttonText, setButtonText] = useState<string>(props.buttonText);
    console.log(buttonText);

    const [sendingForm, setSendingForm] = useState<boolean>(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [cpf, setCpf] = useState('');

    useEffect(() => {
        const { user } = useUser;

        if (user.id) {
            console.log('existe user', user);
            if (user.information) {
                const names = user.information.name.split(' ');
                const firstName = names[0];
                const lastName = user.information.name.substring(firstName.length + 1);
                setFirstName(firstName);
                setLastName(lastName);
                setEmail(user.information.email);
                setPhone(user.information.phone || '');
                setCpf(user.information.cpf);

            } else {
                console.log('occoreu um erro com o nome');
            }


        }
    }, []);

    const attemptSignup = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        if (isAccountPage()) {
            try {
                setSendingForm(true);
                event.preventDefault();
                event.stopPropagation();

                const userToUpdate: UserType = {
                    ...useUser.user,
                };

                if (userToUpdate.information) {
                    userToUpdate.information.name = `${firstName} ${lastName}`;
                    userToUpdate.information.email = email;
                    userToUpdate.information.phone = phone;
                    userToUpdate.information.cpf = cpf;
                }

                console.log('userToUpdate', userToUpdate);
                await updateUserInDB(userToUpdate);

            } catch (error) {
                console.error(error);
                alert(`Ocorreu um erro inesperado. Contate o suporte.\nDetalhes:${JSON.stringify(error)}`);
            } finally {
                setSendingForm(false);
            }
        } else {
            try {
                setSendingForm(true);
                event.preventDefault();
                event.stopPropagation();
                const response: HttpResponse<null> = await signUp(
                    event.currentTarget.nome.value,
                    event.currentTarget.lastname.value,
                    event.currentTarget.email.value,
                    event.currentTarget.celular.value,
                    event.currentTarget.cpf.value,
                    event.currentTarget.password.value
                )

                if (!response.isSuccess) {
                    alert(response.message)
                    return
                }

                window.location.href = '/products';

            } catch (error) {
                console.error(error)
                alert(`Ocorreu um erro inesperado. Contate o suporte.\nDetalhes:${JSON.stringify(error)}`)
            } finally {
                setSendingForm(false)
            }
        }
    }

    function isAccountPage(): boolean {
        const pathName = window.location.pathname;
        return pathName.includes('/account');
    }

    return (
        <SignupPage>
            <SignupContainer>
                <Form onSubmit={attemptSignup}>
                    <Input
                        label="Nome"
                        type="name"
                        name="nome"
                        placeholder="Nome"
                        required={true}
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        disabled={sendingForm}
                    />
                    <Input
                        label="Sobrenome"
                        type="lastname"
                        name="lastname"
                        placeholder="Sobrenome"
                        required={true}
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        disabled={sendingForm}
                    />
                    <Input
                        label="E-mail"
                        type="email"
                        name="email"
                        placeholder="Digite seu e-mail"
                        required={true}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        disabled={sendingForm}
                        readOnly={isAccountPage()}
                    />
                    {
                        !isAccountPage() &&
                        <InputMagicEye
                            label="Senha"
                            name="password"
                            placeholder="Digite sua senha"
                            required={true}
                            disabled={sendingForm} />
                    }
                    <Input
                        label="Celular"
                        type="celular"
                        name="celular"
                        placeholder="(xx) xxxxx xxxx"
                        required={true}
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        disabled={sendingForm}
                    />

                    <Input
                        label="CPF"
                        type="cpf"
                        name="cpf"
                        placeholder="Digite seu cpf"
                        required={true}
                        value={cpf}
                        onChange={(event) => setCpf(event.target.value)}
                        disabled={sendingForm}
                    />

                    <Button type="submit" color="primary" style="filled" disabled={sendingForm}>

                        {!sendingForm ? buttonText : 'Aguarde...'}
                    </Button>

                    <Agreement>
                        <p>
                            Ao criar sua conta você concorda com os nossos
                        </p>
                        <p>
                            <a href="#">Termos e Condições</a>
                        </p>
                    </Agreement>
                </Form>
            </SignupContainer>
        </SignupPage>
    )
}