import React, { useEffect } from "react";
import { ProductType, UserType } from "../../@types";
import { Cart } from "../../@types/cart";
import { Item } from "../../@types/item";
import { Product } from "../../@types/product";
import constants from '../../helpers/constants';
import { getCurrentUserId } from '../domain/users';

export type UserContextType = {
    user: UserType,
    updateUser: (updatedUser: UserType) => void
}

const UserContext = React.createContext<UserContextType>({
    user: {},
    updateUser: (updatedUser: UserType) => { return (null) },
})

export type UserProviderProps = {
    children: React.ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = React.useState<UserType>({

    });


    useEffect(() => {
        // placeholder
    }, [])

    function updateUser(updatedUser: UserType) {
        setUser(updatedUser);
    }



    return (
        <UserContext.Provider value={{ user, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};


export default UserContext;